<template>
  <div v-if="$store.getters.ready">
    <Header nav="project" :group="$route.params.group" :project="$route.params.project" active="members" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix">
            <div class="page-title-left">
              <h5 class="page-title-heading"><div class="breadcrumbs"><router-link :to="`/${project.group.path}`" class="no-link">{{ project.group.name }}</router-link> &bull; <router-link :to="`/${project.group.path}/${project.path}`" class="no-link">{{ project.name }}</router-link></div> Team</h5>
            </div>
            <div class="page-title-right" v-if="self.enable_members || $auth.profile.site_admin">
              <button type="button" class="btn btn-primary btn-rounded" @click="editMember('edit')"><i class="fal fa-user-plus mr-2"></i>Add Member</button>
            </div>
          </div>

          <div class="widget-list">

            <div class="widget-holder" v-for="m in members" :key="m.id+m.email">
              <div class="widget-bg">
                <div class="widget-body pr-1">
                  <div class="row">
                    <div class="col pt-1">
                      <div class="clearfix">
                        <div class="float-left d-none d-sm-inline-block">
                          <img :src="m.avatar" class="rounded-circle avatar mr-3" alt="">
                        </div>
                        <div class="float-left">
                          <strong>{{ m.name ? m.name : m.email }}</strong> <span v-if="m.username">@{{ m.username }}</span> <span v-if="self.id && m.id === self.id" class="badge badge-success"><span class="full">You</span></span> <span v-if="m.two_factor" class="badge badge-primary"><span class="full">2FA</span></span><br>
                          Given access {{ $datefns.fromNow(m.created_at) }} <span v-if="m.expires_at">&bull; Expires in {{ $datefns.fromNow(m.expires_at) }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-1 col-sm-3 text-right pt-2">
                      <div class="clearfix">
                        <div class="float-right" v-if="$auth.profile.site_admin || (self.enable_members && (!getUser(m.username) || !getUser(m.username).site_admin))">
                          <b-dropdown toggle-class="py-0 px-3" variant="link" menu-class="dropdown-card w-dropdown-card-small mt-2" right>
                            <template v-slot:button-content><i class="fal fa-lg fa-ellipsis-v text-muted"></i></template>
                            <div class="card">
                              <header class="card-header d-flex justify-content-between">
                                <i class="fal fa-cog text-primary" aria-hidden="true"></i>
                                <span class="heading-font-family flex-1 text-center fw-400">Settings</span>
                              </header>
                              <div class="card-body list-unstyled dropdown-list">
                                <button type="button" class="dropdown-item" @click="editMember('edit', m)">Edit</button>
                                <div class="dropdown-divider"></div>
                                <button type="button" class="dropdown-item text-danger" @click="editMember('delete', m)"><i class="fal fa-times mr-2"></i>Delete</button>
                              </div>
                            </div>
                          </b-dropdown>
                        </div>
                        <div v-else class="float-right pr-3" style="height: 1px;"></div>
                        <div class="float-right d-none d-sm-inline-block">
                          <i v-if="($auth.profile.site_admin || self.enable_billing) && m.enable_billing" class="fal fa-credit-card text-muted fa-lg pt-1 ml-3"></i>
                          <i v-if="m.enable_files" class="fal fa-code text-muted fa-lg pt-1 ml-3"></i>
                          <i v-if="m.enable_credentials" class="fal fa-key text-muted fa-lg pt-1 ml-3"></i>
                          <i v-if="m.enable_members" class="fal fa-users text-muted fa-lg pt-1 ml-3"></i>
                          <span v-if="getUser(m.username) && getUser(m.username).site_admin" class="badge border ml-3"><span class="full">{{ $config.name }}</span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <b-modal id="editmodal" :title="form.name !== '' ? `Edit ${form.name}` : 'Add Member'" centered>
            <div class="form-group" v-if="form.mode === 'create'">
              <label class="col-form-label">Username or Email Address</label>
              <input type="text" class="form-control" v-model="form.email" maxlength="254">
            </div>
            <div class="mb-4" v-if="!getUser(form.id) || !getUser(form.id).site_admin">
              <label class="col-form-label">Permissions</label>
              <div class="form-group">
                <div class="checkbox checkbox-primary">
                  <label><input type="checkbox" checked disabled><span class="label-text">Create, view, and comment on issues</span></label>
                </div>
              </div>
              <div class="form-group" v-if="self.enable_files || $auth.profile.site_admin">
                <div class="checkbox checkbox-primary">
                  <label><input type="checkbox" v-model="form.enable_files"><span class="label-text">Upload and download files</span></label>
                </div>
              </div>
              <div class="form-group">
                <div class="checkbox checkbox-primary">
                  <label><input type="checkbox" v-model="form.enable_members"><span class="label-text">Add, edit, and remove members</span></label>
                </div>
              </div>
              <div class="form-group" v-if="self.enable_credentials || $auth.profile.site_admin">
                <div class="checkbox checkbox-primary">
                  <label><input type="checkbox" v-model="form.enable_credentials"><span class="label-text">Add, edit, and remove credentials</span></label>
                </div>
              </div>
              <div class="form-group" v-if="self.enable_billing">
                <div class="checkbox checkbox-primary">
                  <label><input type="checkbox" v-model="form.enable_billing" disabled><span class="label-text">View billing and payments</span></label>
                </div>
              </div>
              <div class="form-group" v-if="$auth.profile.site_admin">
                <div class="checkbox checkbox-primary">
                  <label><input type="checkbox" v-model="form.enable_billing"><span class="label-text">Bill user for project</span></label>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="!form.enable_billing">
              <label class="col-form-label">Expires</label>
              <b-form-datepicker v-model="form.expires_at" locale="en-US" hide-header no-highlight-today value-as-date reset-button reset-button-variant="outline-primary" label-reset-button="Clear" label-help="" :date-disabled-fn="disablePastDates" menu-class="dropdown-card w-dropdown-card-medium mt-2"></b-form-datepicker>
            </div>
            <template v-slot:modal-footer>
              <button type="button" class="btn btn-primary btn-rounded mr-auto" @click="saveMember()">Save Changes</button>
            </template>
          </b-modal>

          <b-modal id="deletemodal" title="Delete Member?" centered>
            This member will be removed from the project.
            <template v-slot:modal-footer="{ cancel }">
              <button type="button" class="btn btn-danger btn-rounded mr-2" @click="deleteMember()">Delete</button>
              <button type="button" class="btn btn-default btn-rounded mr-auto" @click="cancel()">Cancel</button>
            </template>
          </b-modal>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin, DropdownPlugin, FormDatepickerPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormDatepickerPlugin);

export default {
  data() {
    return {
      users: {},
      project: {},
      members: [],
      self: {},
      form: {
        mode: 'create',
        id: '',
        email: '',
        name: '',
        enable_billing: false,
        enable_members: false,
        enable_credentials: false,
        enable_files: false,
        expires_at: null,
      },
    };
  },
  methods: {
    disablePastDates(ymd, date) {
      if (date < new Date()) {
        return true;
      }
      return false;
    },
    saveMember() {
      if (this.form.enable_billing) {
        this.form.expires_at = null;
      }
      let email;
      let resource;
      if (this.form.mode === 'update') {
        resource = this.$api.put(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/members/${this.form.id ? this.form.id : this.form.email}`, this.form);
      } else {
        if (this.form.email.indexOf('@') === -1) {
          // try to get user id
          this.form.id = this.getUserID(this.form.email);
          if (!this.form.id) {
            toast.danger(this, 'Username not found');
            return;
          }
          email = this.form.email;
          delete this.form.email;
        } else {
          delete this.form.id;
        }
        resource = this.$api.post(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/members`, this.form);
      }
      resource
        .then((res) => {
          this.$bvModal.hide('editmodal');
          this.$cache.api.remove(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/members`);
          if (res.data.enable_billing) {
            for (let i = 0; i < this.members.length; i += 1) {
              this.members[i].enable_billing = false;
            }
          }
          if (this.form.mode === 'update') {
            for (let i = 0; i < this.members.length; i += 1) {
              if (this.members[i].id === this.form.id && this.members[i].email === this.form.email) {
                this.members[i].enable_billing = res.data.enable_billing;
                this.members[i].enable_members = res.data.enable_members;
                this.members[i].enable_credentials = res.data.enable_credentials;
                this.members[i].enable_files = res.data.enable_files;
                this.members[i].expires_at = res.data.expires_at;
                break;
              }
            }
          } else {
            this.members.push(res.data);
          }
        })
        .catch((e) => {
          if (email) {
            this.$set(this.form, 'email', email);
            this.form.id = '';
          }
          toast.danger(this, e.response.data.message);
        });
    },
    getUserID(username) {
      const u = this.getUser(username);
      if (u) {
        return u.id;
      }
      return undefined;
    },
    getUser(id) {
      if (id) {
        for (let i = 0; i < this.users.length; i += 1) {
          if (this.users[i].username === id || this.users[i].id === id) {
            return this.users[i];
          }
        }
      }
      return undefined;
    },
    deleteMember() {
      this.$api.delete(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/members/${this.form.id ? this.form.id : this.form.email}`)
        .then(() => {
          this.$bvModal.hide('deletemodal');
          this.$cache.api.remove(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/members`);
          for (let i = 0; i < this.members.length; i += 1) {
            if (this.members[i].id === this.form.id && this.members[i].email === this.form.email) {
              this.members.splice(i, 1);
              break;
            }
          }
          if (this.self.id && this.form.id === this.self.id) {
            this.$router.push('/dashboard');
          }
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    editMember(modal, m) {
      if (!m) {
        this.form.mode = 'create';
        this.form.id = '';
        this.form.email = '';
        this.form.name = '';
        this.form.enable_billing = false;
        this.form.enable_members = false;
        this.form.enable_credentials = false;
        this.form.enable_files = false;
        this.form.expires_at = null;
      } else {
        this.form.mode = 'update';
        this.form.id = m.id;
        this.form.email = m.email;
        this.form.name = m.name ? m.name : m.email;
        this.form.enable_billing = m.enable_billing;
        this.form.enable_members = m.enable_members;
        this.form.enable_credentials = m.enable_credentials;
        this.form.enable_files = m.enable_files;
        this.form.expires_at = m.expires_at ? new Date(m.expires_at) : null;
      }
      this.$bvModal.show(`${modal}modal`);
    },
  },
  async mounted() {
    try {
      this.users = (await this.$api.get('/users')).data;
      this.project = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}`)).data;
      this.members = (await this.$api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/members`)).data;
      for (let i = 0; i < this.members.length; i += 1) {
        if (this.members[i].id === this.$auth.profile.id) {
          this.self = this.members[i];
          break;
        }
      }
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
  },
};
</script>

<style scoped>
.avatar { width: 40px; }
</style>
